// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#uploadButton {
    background-color: #114040;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px;
}

input[type="file"] {
    margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/AutoGrader/UploadButton.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["#uploadButton {\n    background-color: #114040;\n    color: #ffffff;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    font-size: 16px;\n    margin: 10px;\n}\n\ninput[type=\"file\"] {\n    margin: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
