// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
  display: flex;
  height: 100vh;
  transition: margin-left 0.3s ease;
}

.sidebar-open {
  margin-left: 250px; /* Adjust based on sidebar width */
}

.sidebar-closed {
  margin-left: 0;
}

.sidebar {
  width: 250px; /* Adjust based on desired sidebar width */
  transition: transform 0.3s ease;
}

.sidebar.closed {
  transform: translateX(-100%);
}
`, "",{"version":3,"sources":["webpack://./src/Dashboard/CSS/dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,kBAAkB,EAAE,kCAAkC;AACxD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY,EAAE,0CAA0C;EACxD,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".main-layout {\n  display: flex;\n  height: 100vh;\n  transition: margin-left 0.3s ease;\n}\n\n.sidebar-open {\n  margin-left: 250px; /* Adjust based on sidebar width */\n}\n\n.sidebar-closed {\n  margin-left: 0;\n}\n\n.sidebar {\n  width: 250px; /* Adjust based on desired sidebar width */\n  transition: transform 0.3s ease;\n}\n\n.sidebar.closed {\n  transform: translateX(-100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
