// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: #f7f3e9;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(229, 231, 235, 0.3);
    position: relative;
  }
  
  .card-header {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #005c69;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 768px) {
    .card {
      padding: 1rem;
    }
  }

  @media (max-width: 480px) {
    .card {
      padding: 0.75rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/quiz/CardComponents.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,qBAAqB;IACrB,2EAA2E;IAC3E,0CAA0C;IAC1C,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE;MACE,aAAa;IACf;EACF;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".card {\n    margin-bottom: 2rem;\n    padding: 1.5rem;\n    background-color: #f7f3e9;\n    border-radius: 0.5rem;\n    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);\n    border: 1px solid rgba(229, 231, 235, 0.3);\n    position: relative;\n  }\n  \n  .card-header {\n    font-size: 1.25rem;\n    font-weight: 600;\n    margin-bottom: 1rem;\n    color: #005c69;\n  }\n  \n  .card-content {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  @media (max-width: 768px) {\n    .card {\n      padding: 1rem;\n    }\n  }\n\n  @media (max-width: 480px) {\n    .card {\n      padding: 0.75rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
