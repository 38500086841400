// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-box {
    background: #c2f1f4;
    border: 2px solid #144044;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.response-box h2 {
    margin-top: 0;
    font-size: 16px;
    color: #333;
}

.response-content {
    margin-top: 10px;
}

.response-item {
    margin-bottom: 10px;
}

.response-label {
    font-weight: bold;
    margin-right: 5px;
}

.response-value {
    color: #144044;
}
`, "",{"version":3,"sources":["webpack://./src/AutoGrader/ResponseBox.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".response-box {\n    background: #c2f1f4;\n    border: 2px solid #144044;\n    border-radius: 4px;\n    padding: 15px;\n    margin-bottom: 10px;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n}\n\n.response-box h2 {\n    margin-top: 0;\n    font-size: 16px;\n    color: #333;\n}\n\n.response-content {\n    margin-top: 10px;\n}\n\n.response-item {\n    margin-bottom: 10px;\n}\n\n.response-label {\n    font-weight: bold;\n    margin-right: 5px;\n}\n\n.response-value {\n    color: #144044;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
