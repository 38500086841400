// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-area {
  transition: margin-left 0.3s ease;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

/* Adjust the content area when the sidebar is open */
.sidebar-open {
  margin-left: 250px; /* Adjust this based on your sidebar's width */
}

/* Adjust the content area when the sidebar is closed */
.sidebar-closed {
  margin-left: 0;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  margin-bottom: 20px;
  color: #144040;
  font-size: 24px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Dashboard/CSS/contentArea.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,aAAa;EACb,WAAW;EACX,sBAAsB;AACxB;;AAEA,qDAAqD;AACrD;EACE,kBAAkB,EAAE,8CAA8C;AACpE;;AAEA,uDAAuD;AACvD;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".content-area {\n  transition: margin-left 0.3s ease;\n  padding: 20px;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n/* Adjust the content area when the sidebar is open */\n.sidebar-open {\n  margin-left: 250px; /* Adjust this based on your sidebar's width */\n}\n\n/* Adjust the content area when the sidebar is closed */\n.sidebar-closed {\n  margin-left: 0;\n}\n\n.section {\n  margin-bottom: 40px;\n}\n\n.section h2 {\n  margin-bottom: 20px;\n  color: #144040;\n  font-size: 24px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
